/*
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-05-31 16:38:50
 * @LastEditors: hutian
 * @LastEditTime: 2021-06-08 21:28:25
 */
export const columns = [
  {
    title: '发货单号',
    dataIndex: 'shipCode',
    key: 'shipCode',
    width: 150,
    align: 'center',
    // ellipsis:true
  },
  {
    title: '订单号',
    dataIndex: 'orderCode',
    key: 'orderCode',
    width: 170,
    align: 'center',
    scopedSlots: { customRender: 'orderCode'}
  },
  {
    title: '订单商品总数量',
    dataIndex: 'goodsCount',
    key: 'goodsCount',
    width: 80,
    align: 'center',
    // ellipsis:true
  },
  {
    title: '已发商品数量',
    dataIndex: 'alreadySendGoodsCount',
    key: 'alreadySendGoodsCount',
    width: 80,
    align: 'center',
    // ellipsis:true
  },
  {
    title: '实发商品发数量',
    dataIndex: 'realSendGoodsCount',
    key: 'realSendGoodsCount',
    width: 80,
    align: 'center',
    // ellipsis:true
  },
  {
    title: '剩余可发数量',
    dataIndex: 'remainingQuantity',
    key: 'remainingQuantity',
    width: 80,
    align: 'center',
  },
  {
    title: '此次发货数量',
    dataIndex: 'shipNum',
    key: 'shipNum',
    width: 80,
    align: 'center',
},
{
    title: '发货状态',
    dataIndex: 'deliveryType',
    key: 'deliveryType',
    align: 'center',
    width: 80,
    scopedSlots: { customRender: 'deliveryType'}
},
{
    title: '审核状态',
    dataIndex: 'shippingOrderStatus',
    key: 'shippingOrderStatus',
    align:'center',
    width: 80,
    scopedSlots: { customRender: 'shippingOrderStatus'}
},

  {
    title: '实际支付价',
    dataIndex: 'actualPrice',
    key: 'actualPrice',
    align: 'center',
    width: 100,
    scopedSlots: {customRender: 'actualPrice'}
  },
  // {
  //   title: '件数',
  //   dataIndex: 'caseNumber',
  //   key: 'caseNumber',
  //   align: 'center',
  //   // ellipsis:true
  // },
  // {
  //   title: '总重量',
  //   dataIndex: 'grossWeight',
  //   key: 'grossWeight',
  //   align: 'center',
  //   // ellipsis:true
  // },
  // {
  //   title: '运费',
  //   dataIndex: 'freight',
  //   key: 'freight',
  //   width:80,
  //   align: 'center',
  //   // ellipsis:true
  // },
  {
    title: '是否开票',
    dataIndex: 'flagInvoice',
    key: 'flagInvoice',
    width: 80,
    align: 'center',
    // ellipsis:true
    scopedSlots: {customRender: 'flagInvoice'}
  },
  {
    title: '收货信息',
    dataIndex: 'address',
    key: 'address',
    // width: 350,
    // ellipsis:true,
    scopedSlots: {customRender: 'address'}
  },
]
