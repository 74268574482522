<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-06-23 18:14:47
 * @LastEditors: hutian
 * @LastEditTime: 2021-06-24 12:14:40
-->
<template>
  <div class="invoice_review">
    <a-modal
      title="审核"
      width="35%"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :footer="null"
      @cancel="visible = false"
      :maskClosable="false"
    >
      <a-form-model :label-col="{ span: 3 }" :wrapper-col="{ span: 10 }">
        <a-form-model-item label="是否通过">
          <a-radio-group v-model="audit_status" @change="onStatusChange">
            <a-radio :value="4"> 通过 </a-radio>
            <a-radio :value="3"> 拒绝 </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="原因" v-if="statusRadio == 3">
          <a-textarea v-model="rejectReason"></a-textarea>
        </a-form-model-item>
      </a-form-model>
      <div class="footer-bts">
        <a-button type="default" @click="visible = false">取消</a-button>
        <a-button type="danger" v-if="statusRadio == 3" @click="onAudit('refuse')">确定</a-button>
        <a-button type="primary" v-if="statusRadio == 4" @click="onAudit('audit')">确定</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'invoice_review',

  data() {
    return {
      confirmLoading: false,
      visible: false,
      rejectReason: '',
      statusRadio: 4,
      audit_status: 4,
      rowData: {}
    }
  },

  computed: {},

  created() {},

  methods: {
    show(row) {
      this.visible = true
      this.rowData = row
    },
    onStatusChange(val) {
      this.statusRadio = val.target.value
    },

    // 审核
    onAudit(name) {
      const _this = this
      let query = {
        id: _this.rowData.id,
        shippingOrderStatus: name == 'audit' ? 2 : 3,
        remark: _this.rejectReason
      }
      _this.axios.post('/api/order/system/orderShipInfo/toExamine', query).then(res => {
        if(res.code == 200) {
          _this.$message.success(res.message)
          _this.rejectReason = ''
          _this.$emit('reload')
          _this.visible = false
        }else {
          _this.$message.error(res.message)
          _this.$emit('reload')
          _this.visible = false
        }
      })
    },
    
  },
}
</script>

<style lang='scss' scoped>
.footer-bts {
  text-align: right;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #e9e9e9;
}
</style>